<template>
  <div class="listPage">
    <div class="line" v-if="list.length > 0"></div>
    <div class="listBox" v-if="list.length > 0">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <List
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <template v-for="(item, index) in list">
            <div :key="index" class="meet">
              <div class="meetName" @click="goDetailPage(item)">
                <div class="name">
                  <img src="../../assets/unit.png" width="15px" />
                  <span>{{item.memberTypeName}}</span>
                </div>
                <Icon class="arrow" name="arrow" size="16" color="#666"/>
              </div>
              <div class="meetName meetTime" @click="goDetailPage(item)">
                <div class="timeBox">                  
                    <span >缴费年限：</span>
                    <span class="time">{{item.season}}年</span>                  
                  <!-- <div>
                    <span >开始时间：</span>
                    <span class="time">2021-06-03</span>
                  </div> -->
                  <!-- <div>
                    <span >结束时间：</span>
                    <span class="time">2021-06-21</span>
                  </div> -->
                  
                </div>
                <span class="warning" v-if="item.paymentMethod==2&&item.operationStatus==0">待审核</span>
                <span class="error" v-else-if="item.paymentMethod==2&&item.operationStatus==2">缴费驳回</span>
                <span v-else :class="item.payStatus==2?'blueColor':'normal'">{{['缴费成功','退费审核中','退费驳回','退费成功'][item.payStatus-2]}}</span>
              </div>
            </div>
          </template>
        </List>
      </van-pull-refresh>
    </div>
    <div v-else class="noMeet">
      <Icon name="notes-o" color="#999" size="100" />
      <span>暂时没有任何记录</span>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, Icon, List } from "vant";
import {getPayRecord} from "@/api/member.js"

export default {
  name: "myInvoice",
  components: {
    Tab,
    Tabs,
    Icon,
    List,
  },
  data() {
    return {
      activeIdx: 0,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created(){
    this.getList(1)
  },
  methods: {
    getList(page){
      let clientId = localStorage.getItem("clientId");
      if (this.list.length < this.total || page == 1) {
        getPayRecord({
          clientId,
          orderSource:"1",
          pageIndex: page,
          pageSize: this.pageSize,
        }).then((res) => {
          this.loading = false;
          this.refreshing = false;
          if (res.status == "S") {
            let { list, total } = res.data;
            let newList = page == 1 ? list : [...this.list, ...list];
            this.list = [...newList];
            this.pageIndex = page;
            this.total = total;
          }
        });
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
    },
    goDetailPage(item) {     
        this.$router.push("/memberRecord?orderId="+item.id);
    },
    onLoad() {
      this.getList(this.pageIndex + 1);
    },
    onRefresh() {
      this.loading = true;
      this.total=0;
      this.finished = false;
      this.getList(1);
    },
  },
};
</script>

<style lang="less" scoped>
@import "./../meetList/index.less";
.listPage {
  padding-top: 0rem;
  .line {
    height: 0.12rem;
    background: #f5f5f5;
  }
  .listBox {
    max-height: calc(~"100vh - 0.12rem");
    overflow-y: auto;
    .meetTime {
      .timeBox{
        height:0.23rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
      .blueColor{
        color:#0FA84F
      }
      .warning{
        color: #1989fa;
      }
      .error{
        color: #ee0a24;
      }
      .normal{
        color:#999
      }
    }
  }
  .noMeet {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      margin-top: 0.3rem;
      color: #999;
      font-size: 22px;
      font-weight: bold;
    }
  }
}
</style>
